@import './variable';

.demoSection {
  min-height: 75vh;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 1400px;
  margin: 0 auto;

  hr {
    color: black;
    opacity: 1;
    margin-top: 3rem;
    opacity: 50%;
  }

  .title {
    font-weight: 700;
    font-size: 25px;
    margin-top: 6rem;
    margin-bottom: 4rem;
    button {
      font-size: 14px;
    }
  }

  .description {
    display: grid;
    grid-auto-columns: auto;
    column-gap: 60px;
    align-items: center;
    #photo {
      grid-row: 1;
      grid-column: 1;
    }

    #text {
      grid-row: 1;
      grid-column: 2;
      text-align: start;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      button {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1400px) {
  .demoSection {
    margin: 0 auto;
    max-width: 97vw;
  }
}

@media (max-width: 1000px) {
  .demoSection {
    max-width: 95vw;
    .title {
      margin-top: 3rem;
      margin-bottom: 3rem;
      font-size: 20px;
    }
    .description {
      #photo {
        grid-row: 1;
        grid-column: 1;
        img {
          width: 100%;
          height: 100%;
        }
      }

      #text {
        grid-row: 2;
        grid-column: 1;
        padding: 2rem;
      }
    }
  }
}
