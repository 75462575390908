@import '../../../styles/variable';

.Main {
  min-height: 75vh;
  max-width: 1400px;
  margin: 0 auto;

  .title {
    font-weight: 700;
    font-size: 25px;
    padding-top: 5rem;
    padding-bottom: 2rem;
    text-align: center;
  }

  .card {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  margin-bottom: 5rem;
}

@media (max-width: 1400px) {
  .Main {
    max-width: 95vw;
  }
}
