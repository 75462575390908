@import '../../../styles/variable';
h3 {
  font-size: 24px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  font-weight: 700;
}
.sample {
  margin: 0 auto;
  padding-top: 5rem;
  padding-bottom: 10rem;

  .optimal {
    margin: 0 auto;
    text-align: center;
    max-width: 1150px;
    font-size: 15px;

    #first {
      font-weight: 700;
    }
    #second {
      padding-left: 5px;
    }
  }

  .setting {
    font-size: 1.1rem;
    padding: 2rem;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.demo {
  margin-top: 0.5rem;
  border: 1px solid $dark;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.feedback {
  margin-top: 1rem;
  text-align: end;
  margin-bottom: 5rem;
  a {
    color: $dark;
    font-weight: 600;
    font-size: 0.9rem;
  }
  a:hover {
    color: $blue;
  }
}

@media screen and (max-width: 600px) {
  .sample {
    .setting {
      font-size: 0.9rem;
    }
  }
}
