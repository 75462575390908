@import '../../../styles/variable';

.blockchain {
  padding-bottom: 5rem;
  button {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  canvas {
    margin: 0 auto;
    max-width: 80vw;
  }
}
