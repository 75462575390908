@import '../../styles/variable';

.Footer {
  background-color: $dark;

  .container {
    color: white;
    min-height: 18vh;
    text-align: center;
    display: grid;
    grid-template-columns: auto;
    font-size: 12px;
    max-width: 1400px;
    margin: 0 auto;
    .one {
      grid-row: 1;
      grid-column: 1;
      display: flex;
      align-items: center;
    }

    .two {
      grid-row: 1;
      grid-column: 2;
      display: flex;
      align-items: center;
      margin: 0 auto;
    }

    .three {
      grid-row: 1;
      grid-column: 3;
      display: flex;
      align-items: center;
      float: right;
      margin-left: auto;

      img {
        margin-left: 1rem;
      }
    }
  }
}

@media (max-width: 1400px) {
  .Footer {
    .container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
