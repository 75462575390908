@import './variable';

//NavBar
.contact {
  color: $blue;
  background-color: white;
  border: 1px solid $blue;
  border-radius: 21px;
  width: 110px;
  height: 42px;
  font-weight: 600;
  font-size: 14px;

  a {
    color: $blue;
    text-decoration: none;
  }
}

.contact:hover {
  background-color: $blue;
  color: white;
  a {
    color: white;
  }
}

.nav {
  background-color: transparent;
  border: transparent;
  color: $dark;
  width: 110px;
  height: 42px;
  font-weight: 600;
  font-size: 14px;
}

//Demo Card
.card {
  background-color: transparent;
  border: transparent;
  position: relative;
  top: 0;
  transition: top ease 0.2s;
  border-radius: 10px;
  color: $dark !important;
  text-decoration: none !important;
}

.card:hover {
  top: -3px;
}

.tag {
  width: 70px;
  height: 32px;
  background-color: $blue;
  border: $blue;
  color: white;
  border-radius: 21px;
}

.tagSoon {
  width: 70px;
  height: 32px;
  background-color: $gray;
  border: $gray;
  color: white;
  border-radius: 21px;
}

//demopage
.link {
  font-size: 13px;
  font-weight: 600;
  color: $dark;
  border: 1px solid $dark;
  background-color: transparent;
  border-radius: 25px;
  // padding-right: 17px;
  // padding-left: 17px;
  // padding-top: 12px;
  // padding-bottom: 12px;
  width: 100px;
  height: 42px;
  margin-top: 30px;
  a {
    color: $dark;
    text-decoration: none;
  }
}
