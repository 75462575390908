@import '../../../styles/variable';

.ood {
  padding: 2rem;
  #imgbox {
    height: 244px;
    width: 547px;
    border: 1px solid $dark;
    border-style: dashed;
    margin: 0 auto;
    display: flex;
    align-items: center;
    p {
      margin: 0 auto;
      color: $gray;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
}
