@import '../../../styles/variable';

.pose {
  padding: 2rem;
  #imgbox {
    height: 400px;
    width: 500px;
    border: 1px solid $dark;
    border-style: dashed;
    margin: 0 auto;
    display: flex;
    align-items: center;
    p {
      margin: 0 auto;
      color: $gray;
    }

    img {
      height: 110%;
      width: 110%;
    }
  }
}
