@import '../../../styles/variable';

.card {
  margin-top: 3rem;
  border: transparent;
  height: 320px !important;
  width: 432px !important;
  background-color: $blue;
  text-align: left;
  border-radius: 10px !important;

  img {
    position: relative;
  }

  button {
    position: absolute;
    top: 22px;
    right: 22px;
  }

  #title {
    font-size: 1rem;
    font-weight: 700;
    color: white;
  }

  #text {
    font-size: 0.8rem;
    color: white;
  }
}

@media (max-width: 450px) {
  .card {
    width: 95vw !important;
  }
}
