@import '../../../styles/variable';
.demo {
  display: grid;
  #left {
    grid-row: 1;
    grid-column: 1;
    margin: 0 auto;
    width: 800px;
    padding: 1rem;

    #top {
      border: 1px solid $border;
      border-radius: 5px;
      height: 140px;
      margin-bottom: 2rem;
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .terminal {
        img {
          margin: 1rem;
        }
        i {
          font-style: normal;
          font-size: 0.8rem;
        }
      }
    }

    #middle {
      display: grid;
      height: 100px;
      margin: 0 auto;
      .circle {
        grid-row: 1;
        grid-column: 1;
        text-align: start;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        span {
          vertical-align: middle;
        }
        #circle_air {
          height: 15px;
          width: 15px;
          background-color: white;
          border-radius: 50%;
          display: inline-block;
          border: 1px solid $dark;
        }

        #circle_silicon {
          height: 15px;
          width: 15px;
          background-color: $blue;
          border-radius: 50%;
          display: inline-block;
          border: 1px solid $dark;
        }
      }
      .option {
        grid-row: 1;
        grid-column: 2;
        display: grid;
        column-gap: 20px;
        margin: 0 auto;
        font-weight: 700;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        #option_wavelength {
          grid-row: 1;
          grid-column: 1;
        }
        #option_angle {
          grid-row: 1;
          grid-column: 2;
        }
      }
    }

    #bottom {
      height: 200px;
      padding: 0;
      margin: 0 auto;
      margin-top: 1rem;
      button {
        background-color: transparent;
        border: transparent;
        padding: 0;
        margin: 0;
      }
      #point {
        width: 12px;
        height: 25px;
        margin-left: -225px;
      }
      .cell {
        margin-top: 1rem;
        font-weight: 600;
        font-size: 1.1rem;
        button {
          margin-right: 0.5rem;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 8px;
        }
        #active {
          border: 1.5px solid $blue;
          background-color: transparent;
          color: $blue;
        }
        #deactive {
          border: 1.5px solid $border;
          background-color: transparent;
          color: $border;
        }
        #deactive:hover {
          border: 1.5px solid $gray;
          background-color: transparent;
          color: $gray;
        }
      }
    }
  }

  #right {
    grid-row: 1;
    grid-column: 2;
    margin: 0 auto;
    p {
      padding-top: 1rem;
    }
    svg {
      padding: 0;
      margin: 0;
      height: 15px;
      display: inline;
      margin-left: 250px;
    }
    img {
      text-align: center;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 1300px) {
  .demo {
    #left {
      grid-row: 1;
      grid-column: 1;
      width: 90%;
    }

    #right {
      grid-row: 2;
      grid-column: 1;
      width: 90%;
    }
  }
}

@media screen and (max-width: 980px) {
  .demo {
    #left {
      grid-row: 1;
      grid-column: 1;
      width: 90%;
      #bottom {
        height: 170px;
        #point {
          width: 1vw;
          height: 2vw;
          margin-left: -19vw;
        }
        button {
          .cell64 {
            width: 1vw;
          }
          .cell32 {
            width: 2vw;
          }
          .cell16 {
            width: 4vw;
          }
        }
        img {
          width: 64vw;
        }
      }
    }

    #right {
      grid-row: 2;
      grid-column: 1;
      width: 90%;
      p {
        padding-top: 0rem;
      }
      img {
        max-width: 70vw;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .demo {
    #left {
      #top {
        .terminal {
          font-size: 0.9rem;
          img {
            width: 70px;
            height: 70px;
          }
        }
      }
      #middle {
        .circle {
          font-size: 10px;
        }
        .option {
          font-size: 12px;
        }
      }
      #bottom {
        .cell {
          font-size: 12px;
        }
      }
    }
    #right {
      img {
        max-width: 70vw;
        max-height: 100vw;
      }
    }
  }
}
