@import '../../../styles/variable';

.demo {
  border: 1px solid $dark;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: left;
  padding: 2rem;
  position: relative;
  margin-top: 3rem;

  #label {
    width: max-content;
    background-color: black;
    color: white;
    border-radius: 20px;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    top: -1rem;
    font-size: 0.9rem;
  }
}

.colorPoint {
  justify-content: flex-end;
  position: relative;
  bottom: -3rem;
  display: flex;
  font-size: 0.9rem;
  font-weight: 600;

  #yellow {
    width: 30px;
    height: 20px;
    background-color: #fff500;
    margin-right: 0.5rem;
  }

  #red {
    width: 30px;
    height: 20px;
    background-color: #ff0300;
    margin-right: 0.5rem;
  }

  #blue {
    width: 30px;
    height: 20px;
    background-color: #001aff;
    margin-right: 0.5rem;
  }
}

.demo_gif {
  border: 1px solid $dark;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: #d9d9d9;

  img {
    width: 100%;
    height: auto;
  }

  #label {
    width: max-content;
    background-color: black;
    color: white;
    border-radius: 20px;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    top: -1rem;
    margin-left: 2rem;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 600px) {
  .demo {
    padding: 1rem;
    #label {
      font-size: 0.7rem;
    }
  }
  .demo_gif {
    padding: 1rem;
    #label {
      font-size: 0.7rem;
      margin-left: 0rem;
    }
  }

  .colorPoint {
    font-size: 0.7rem;

    #yellow {
      width: 25px;
      height: 15px;
      margin-right: 0.1rem;
    }

    #red {
      width: 25px;
      height: 15px;
      margin-right: 0.1rem;
    }

    #blue {
      width: 25px;
      height: 15px;
      margin-right: 0.1rem;
    }
  }
}

#bottom {
  margin-top: 3rem;
  margin-bottom: 10rem;
}
